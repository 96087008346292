import './App.css';
import Games from './components/Games'
import AppBar from './components/AppBar'

function App() {
  return (
    <>
      <AppBar />
      <Games />
    </>
  );
}

export default App;
